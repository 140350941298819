<template>
  <div class="search-box col-lg-12 p-0 m-0">
    <div class="input-group d-flex">
      <input type="text" class="form-control" placeholder="Procurar" aria-label="Procurar" v-model="searchText">
      <span class="input-group-btn">
        <button class="btn color-button text-light" type="button" @click="searchClicked">Procurar</button>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      searchText: '',
      events: []
          
    }
  },
  methods: {
    searchClicked()
    {
      this.$emit('search-clicked', this.searchText);
    }
  }
}
</script>
<style scoped>
.input-group
{
  gap: 8px;
}

input{
  color: rgb(78, 84, 200) !important;
  font-weight: bold;
  padding-left: 3rem !important;
  /* */
  background-image: url("../assets/dashboard/search.png");
  background-repeat:no-repeat;
  background-position: 8px center;  
  background-size: auto 50%;
}

.color-button
{
  cursor: pointer;
  background-color: rgb(78, 84, 200);
  border-radius: 10px;
}
</style>