export default class InputGetAlerts
{
  constructor( pageNumber, pageSize, searchText, orderBy, propertyName, type, moduleId) 
  {
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.searchText = searchText;
    this.orderBy = orderBy;
    this.propertyName = propertyName;
    this.type = type;
    this.moduleId = moduleId;
  }    
}