<template>
  <div class="py-5">
    <!-- Status picker and add alert -->
    <div class="d-flex justify-content-between flex-wrap">
      <div class="col-lg-6 m-0 p-0 text-left"> 
        <button v-bind:class="{'font-weight-bold': type == HISTORY_TYPE}" class="type-button" @click="type = HISTORY_TYPE">Arquivo</button> <span  v-bind:class="{'font-weight-bold': type == HISTORY_TYPE}">({{historyCount}})</span> | <button v-bind:class="{'font-weight-bold': type == MINE_TYPE}" class="type-button" href="#" @click="type = MINE_TYPE">Criados por mim</button> <span v-bind:class="{'font-weight-bold': type == MINE_TYPE}">({{mineCount}})</span>
      </div>
      <button id="add-button" type="button" class="btn color-button text-light d-flex align-items-center" @click="addAlert">
        <span class="d-none d-lg-block">Adicionar Alerta</span>
        <span class="d-block d-lg-none font-weight-bold">+</span>
      </button>
    </div>
    <!-- Search bar -->
    <div class="d-flex align-items-center flex-wrap my-4">
      <div class="col-12 col-lg-6">
        <SearchBar @search-clicked="searchClicked"></SearchBar>
      </div>
    </div>
    <!-- List: this will have different UI on mobile -->
    <CTableWrapper ref="table" hover striped :caption="caption" :items="items" :actions="actions" :pages="pages" :fields="fields" clickable-rows @row-clicked="rowClicked" @action-clicked="actionClicked" @update-requested="updateItemsRequested" :sorter='{ external: false, resetable: false }' :loading="loading" paginationSubtitle="Alertas por Página">
    </CTableWrapper>
  </div>
</template>
<script>
import SearchBar from '@/controls/SearchBar.vue'
import { mapState, mapActions } from 'vuex'
import CTableWrapper from '@/controls/PaginatedTable.vue'
import InputGetAlerts from "@/classes/InputGetAlerts";
import { UTILS_HELPER } from "@/helpers/utils.js";
import router from '@/router/index';
import EventBus from '@/events/bus.js';

const MINE_TYPE = "Mine";
const HISTORY_TYPE = "History";

export default {
  components: { CTableWrapper, SearchBar },
  data () {
    return {
      currentPage: 1,
      loading: false,
      type: HISTORY_TYPE,
      caption: "Alertas",
      itemsPerPage: localStorage.itemsPerPage ?? 10,
      fields: [
        { key: 'readableDate', label: ('Data'), _classes: 'font-weight-bold', sortable: false },
        { key: 'title', label: ('Título'), _classes: 'font-weight-bold', sortable: false },
        { key: 'content', label: ('Conteúdo'), _classes: 'font-weight-bold', sortable: false },
        { key: 'moduleName', label: ('Imóvel'), _classes: 'font-weight-bold', sortable: false },
        { key: 'usersNames', label: ('Destinatários'), _classes: 'font-weight-bold', sortable: false },
      ],      
      actions: [
        { key: 'view', image: 'iconRightArrowWhite.png', imageBig: 'iconRightArrow.png' },
        { key: 'remove', image: 'iconCloseWhite.png', imageBig: 'iconClose.png' }
      ],
      // Provide access to consts on HTML
      MINE_TYPE: MINE_TYPE,
      HISTORY_TYPE: HISTORY_TYPE
    }
  },
  computed: {
    ...mapState('alerts', ['listOutput']),
    ...mapState('alerts', ['processTask']),
    alertsInfo: function() {
      return this.listOutput && this.listOutput.data ? this.listOutput.data : null;
    },
    items: function()
    {
      var result = [];

      if(this.alertsInfo && this.alertsInfo.alerts)
      {
          result = this.alertsInfo && this.alertsInfo.alerts;

          result.forEach(item => {
              item["readableDate"] = UTILS_HELPER.formatDateTime(item.moment);
              item["moduleName"] = item.module ? item.module.name : "-";
              item["usersNames"] = item.users && item.users.length ? item.users.map(x=> x.name).join(", ") : "Todos";
          });
      }

      return result;
    },
    historyCount: function()
    {
      return this.alertsInfo ? this.alertsInfo.history : 0;
    },
    mineCount: function()
    {
      return this.alertsInfo ? this.alertsInfo.mine : 0
    },
    pages: function()
    {
      return this.listOutput ? this.listOutput.totalPages : 1;
    },
  },
  mounted: function()
  {
    // Active have default value of true
    this.active = true;
    this.updateItemsRequested(1, this.itemsPerPage);
  },
  methods: {
    ...mapActions('alerts', ['getAlertList']),
    ...mapActions('alerts', ['removeAlert']),
    rowClicked (item) {
      this.$router.push({name: "EditAlert", params: { alert: item } });
    },
    actionClicked(item, action){
      switch(action.key)
      {
        case 'view':
        {
          this.$router.push({name: "EditAlert", params: { alert: item } });
          break;
        }
        case 'remove':
        {
          EventBus.$emit("warning-alert-requested", { message: `Tem a certeza que pretende remover o alerta '${item.title}'?`, primaryCallback: () =>
          {
            this.loading = true;
            this.removeAlert(item.id);
          }, secondaryButton: "Cancelar"});
          break;
        }
        default:
        {
          throw "Ação não suportada!"; 
        }
      }
    },
    updateItemsRequested(currentPage, itemsPerPage)
    {
      this.loading = true;
      
      //Make request
      this.currentPage = currentPage;
      this.itemsPerPage = itemsPerPage;
      const propertyName = "id";
      const orderBy = 0;

      var input = new InputGetAlerts(currentPage, itemsPerPage, this.searchText, orderBy, propertyName, this.type);
      this.getAlertList(input);       
    },
    addAlert () 
    {
      // alert("Em desenvolvimento");
      router.push({ path: '/alertas/adicionar' });
    },
    searchClicked(searchText)
    {
      this.searchText = searchText;
      this.$refs.table.reset();
      this.updateItemsRequested(1, this.itemsPerPage);
    }, 
  },
  watch:
  {
    type: function (val) {
      // Update actions
      switch(val) {
        
        case HISTORY_TYPE:
          {
            this.active = true;
            this.actions = [];        
            this.actions.push({ key: 'view', image: 'iconRightArrowWhite.png', imageBig: 'iconRightArrow.png' });
            this.actions.push( { key: 'remove', image: 'iconCloseWhite.png', imageBig: 'iconClose.png' });
            break;
          }
        case MINE_TYPE:
          {
            this.active = false;
            this.actions = [];
            this.actions.push({ key: 'view', image: 'iconRightArrowWhite.png', imageBig: 'iconRightArrow.png' });
            this.actions.push( { key: 'remove', image: 'iconCloseWhite.png', imageBig: 'iconClose.png' });
            break;
          }
        default:
          {
            throw "The provided type is not supported!"; 
        }
      }

      // Update items
      this.$refs.table.reset();
      this.updateItemsRequested(1, this.itemsPerPage);
    },
    listOutput: function()
    {
      this.loading = false;
    },
    processTask: function(val)
    {
      if(val !== null)
      {
        this.loading = false;
        this.updateItemsRequested(this.currentPage, this.itemsPerPage);
      }
    }
  }
}
</script>

 
<style>
th
{
  border-top: none !important;
  color: white;
  background-color: rgb(143, 148, 251) !important; 
}

td
{
  color: rgb(143, 148, 251);
  font-weight: bold;
}

tr
{
  cursor: pointer;
}

.type-button
{
  cursor: pointer;
  border: none;
  padding: 0;
  text-decoration: none;
  color: rgb(143, 148, 251);
  font-size: 25px;
}

.color-button{
  cursor: pointer;
  background-color: rgb(78, 84, 200);
  border-radius: 15px;
}

.dropdown-container
{
  gap: 8px;
}

#add-button:hover span
{
  color: whitesmoke;
}

@media only screen and (max-width: 991px) {
   #add-button
  {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    position: relative;
  }
  #add-button > span
  {
    font-size: 28px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
  }
}
@media only screen and (max-width: 435px) {
   .type-button {
    font-size: 19px;
    margin: 0 0 10px 0;
  }
}



</style> 